
import { footerNavigationLinks } from '../../../fc/Constants/config-footer'

export default {
  name: 'FooterNavigation',
  computed: {
    footerNavigationLinks () { return footerNavigationLinks(this) },
    footerNavigationLinksTopWeb () { return this.footerNavigationLinks },
    footerNavigationLinksBottomWeb () { return [] },
    footerNavigationLinksTopMobile () { return this.footerNavigationLinks.slice(0, 4) },
    footerNavigationLinksBottomMobile () { return [this.footerNavigationLinks[4]] }
  },
  methods: {
    openFreshdesk () {
      this.$bus.$emit('OPEN_FW')
    }
  }
}
